import { useAppStore } from '@src/store/app';
import { useChatStore } from '@src/store/chat';
import ChatCacheService from '@src/services/chat-cache-service';
import { withCatch } from '@utils/await-safe';
import { Logger } from '@utils/logger';
import Pubnub, { LinearRetryPolicy } from 'pubnub';
import { Channel, Chat, Membership, Message, MessageMentionedUsers, User as ChatUser } from '@pubnub/chat';
import { markRaw } from 'vue';
import { wrapPubnubMessageToFriendlyObservable } from '@utils/chat/message-wrapper';
import AudioService from '@src/services/audio-service';
import { sendNotification } from '@tauri-apps/plugin-notification';
import { ChatAuthDetails, KnownChatChannel, KnownChatUser, MyTimeInChatMessage } from '@src/types/ChatAndMessaging';
import { addToArrayInMap, removeFromArrayInMapWithPredicate } from '@utils/map-utils';
import { restoreSerializedMap } from '@utils/serializers';
import { DateTime } from 'luxon';
import { invoke } from '@tauri-apps/api/core';

const log = new Logger('SERVICE:CHAT');

// const MESSAGES_TO_LOAD_MAX = 100;
// const GLOBAL_IGNORE_CHANNELS = [];
// const EVENT_USER_ONLINE = 1;
// const EVENT_USER_OFFLINE = 2;
// const EVENT_USER_IDLE = 3;
// const EVENT_MESSAGE_DELETED = 101;
// const EVENT_USER_UPDATED = 102;
// const MYTIMEIN_BROADCAST_CHANNEL = 'mytimein-broadcast';

class ChatService {
  private static instance: ChatService;
  private chat: Chat | null = null;

  appStore: ReturnType<typeof useAppStore>;
  chatStore: ReturnType<typeof useChatStore>;

  private currentRouteName: string = '';
  private isWindowFocused: boolean = false;
  private channelDisconnectFns = new Map<string, () => void>();
  private customStatusListenerFn: (() => void) | null = null;
  private customEventListenerFn: (() => void) | null = null;
  private inviteListenerFn: (() => void) | null = null;
  private currentChannelListDisconnectFunction: (() => void) | null = null;
  private currentChannelUpdaterInterval: NodeJS.Timeout | null = null;
  private tokenRefreshInterval: NodeJS.Timeout | null = null;
  private currentChannelParticipantListStreamDisconnectFunction: (() => void) | null = null;
  private currentChannelStopTypingUpdatesDisconnectFunction: (() => void) | null = null;
  private pendingMessageUpdateStreams = new Map<string, (() => void)[]>();

  private currentChannelLastCheckedAt: DateTime | null = null;
  private tokenExpirationTime: Date | null = null;

  private loadingChannels = new Set<string>();
  private finishedLoadingChannels = new Set<string>();

  // @todo: im not sure if this is actually needed, to be honest.

  private currentlyTyping = new Map<string, NodeJS.Timeout>();

  private authentication: ChatAuthDetails = {
    token: '',
    ttl: 0,
    publish_key: '',
    subscribe_key: '',
  };

  private currentRecoveryAttempts = 0;
  private busyUpdatingToken = false;
  // internal only
  private PUBLISH_KEY: string = '';
  private SUBSCRIBE_KEY: string = '';

  private constructor() {
    this.appStore = useAppStore();
    this.chatStore = useChatStore();

    this.customEventListenerFn = null;
    this.customStatusListenerFn = null;
    this.inviteListenerFn = null;
  }

  public static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }
    return ChatService.instance;
  }

  initializeChat = async () => {
    this.loadUserFromStorage();
    this.loadChannelOrderFromStorage();
    const initializedChatToken = await this.initializeChatToken();
    if (!initializedChatToken) {
      return;
    }
    const initializedChat = await this.createPubNubChat(`${this.appStore.currentUser?.id ?? -1}`);
    this.chatStore.chatUserId = `${this.chat?.currentUser?.id}`;

    if (!initializedChat) {
      return;
    }
    this.initializeListeners();

    const [memberShipError, success] = await withCatch(this.handleMemberships());

    if (memberShipError || !success) {
      log.error('Failed to get memberships');
      log.error(memberShipError);
    }

    this.postInitialization();
  };

  createPubNubChat = async (userId: string): Promise<Boolean> => {
    log.info(`Initializing chat with user id`, userId);
    const linearRetryPolicy = LinearRetryPolicy({
      delay: 5000,
      maximumRetry: 10, //This cannot exceed more than 10 or Pubnub yells at you
    });
    const [chatInitError, chat] = await withCatch(
      Chat.init({
        publishKey: this.PUBLISH_KEY,
        subscribeKey: this.SUBSCRIBE_KEY,
        userId: `mytimein-user-${userId}`,
        storeUserActivityTimestamps: true,
        authKey: this.authentication.token,
        restore: true,
        listenToBrowserNetworkEvents: true,
        retryConfiguration: linearRetryPolicy,
        subscribeRequestTimeout: 55,
      })
    );

    if (chatInitError) {
      log.error('Failed to initialize chat', chatInitError);
      return false;
    }

    this.chat = markRaw(chat as Chat);
    this.chatStore.chatUserId = userId;
    this.chatStore.onlineUserIds.add(userId);
    log.info('Chat Intialized');

    return true;
  };

  initializeChatToken = async (): Promise<boolean> => {
    log.info('Getting chat token');
    const [refreshError, chatToken] = await withCatch(this.appStore.api.refreshChatToken());

    if (refreshError || !chatToken) {
      log.error('Failed to get chat token', refreshError);
      return false;
    }

    log.info('Got new chat token');
    this.authentication = chatToken;

    log.info('Getting Signed Cookie');
    const [cookieFetchError, _signedCookie] = await withCatch(this.appStore.api.getSignedCookie(this.authentication.token));
    if (cookieFetchError) {
      log.error('Failed to get signed cookie', cookieFetchError);
      // return false;
    }

    log.info('Got Signed Cookie');

    const { publish_key, subscribe_key, ttl } = this.authentication;
    this.tokenExpirationTime = new Date(Date.now() + ttl * 1000 - 10000);
    this.scheduleTokenRefresh();

    this.PUBLISH_KEY = publish_key;
    this.SUBSCRIBE_KEY = subscribe_key;

    return true;
  };

  initializeListeners = () => {
    this.initializeStatusListener();
    this.initializeReactionHandler();
  };

  scheduleTokenRefresh = () => {
    if (this.tokenRefreshInterval) {
      clearInterval(this.tokenRefreshInterval);
    }
    this.tokenRefreshInterval = setInterval(async () => {
      if (this.tokenExpirationTime && Date.now() > this.tokenExpirationTime.getTime()) {
        await this.refreshChatToken();
      }
    }, 5000);
  };

  postInitialization = () => {
    this.currentRecoveryAttempts = 0;
    this.listenForInvites();
    this.streamUpdatesOnCurrentChannelList();
    this.restoreNotifications();
    // store.broadcastOnlineNotice();
    this.getUnreadMessageCounts().catch();
    this.restoreLastVisibleChannelAfterDelay();
    this.restoreMutedChannels();
    // subscribeToSignalsOnDev(store);
    this.chatStore.initialLoad = false;
  };

  restoreLastVisibleChannelAfterDelay = () => {
    setTimeout(() => {
      const lastVisibleChannel = localStorage.getItem('current-channel');
      if (lastVisibleChannel) {
        this.changeChannel(lastVisibleChannel).catch();
      }
    }, 2000);
  };

  changeChannel = async (channelId: string): Promise<boolean> => {
    log.info('Changing channel to', channelId);
    this.chatStore.channelId = channelId;

    const channel = this.chatStore.knownChannels.get(channelId) as Channel;

    if (channel && !this.channelDisconnectFns.has(channelId)) {
      log.info('Channel already known, connecting to it', channelId);

      // TODO: what do we do here?
      const [connectError] = await withCatch(this.connectToChannel(channel));
      if (connectError) {
        log.error('Failed to connect to known channel.');
        log.error(connectError);
        return false;
      }

      this.startCurrentChannelInterval(channel);
    }

    const isChannelMessagesEmpty = !this.chatStore.channelMessages.has(channelId) || this.chatStore.channelMessages.get(channelId)?.length === 0;
    if (isChannelMessagesEmpty) {
      log.info('Loading messages for channel. ', channelId);
      const [loadMessageError] = await withCatch(this.loadChannelMessages(channelId));

      if (loadMessageError) {
        log.error('Failed to load channel messages');
        log.error(loadMessageError);
      }
    }

    this.dumpCurrentlyTypingList();
    // this.watchTyping(id);
    // await this.watchPresence(id);

    //Only do this if we are in the router view of chat
    if (this.currentRouteName === 'chat') {
      this.chatStore.removeAlerts(channelId);
      this.chatStore.removeMentions(channelId);
    }

    // clear local mutations
    this.chatStore.currentChannelMutations = 0;
    localStorage.setItem('current-channel', channelId);
    return true;
  };

  watchTyping = async (id: string) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }
    const channel = this.chatStore.knownChannels.get(id);

    if (!channel) {
      throw new Error('Channel not found');
    }
    this.dumpCurrentlyTypingList();
    if (this.currentChannelStopTypingUpdatesDisconnectFunction) {
      log.info('Unregistering the current typing watch.');
      this.currentChannelStopTypingUpdatesDisconnectFunction();
    }
    this.currentChannelStopTypingUpdatesDisconnectFunction = channel.getTyping(this.typingCallbackHandler);
  };

  isChannelLoadingOrFinished(id: string): boolean {
    return this.loadingChannels.has(id) || this.finishedLoadingChannels.has(id);
  }

  logSkipLoading(id: string): void {
    log.info('Skipping load: already loading or finished', id, {
      loading: this.loadingChannels.has(id),
      finished: this.finishedLoadingChannels.has(id),
    });
  }

  async fetchAllMessages(channel: Channel, id: string): Promise<Message[]> {
    const discoveredMessages: Message[] = [];
    let shouldGetMore = true;

    while (shouldGetMore) {
      const lastTimetoken = discoveredMessages[0]?.timetoken ?? this.chatStore.channelMessages.get(id)?.[0]?.timetoken ?? null;

      const params = {
        count: 50,
        startTimetoken: lastTimetoken ? `${lastTimetoken}` : undefined,
      };

      log.debug('Loading messages for channel', id, params);

      const [err, history] = await withCatch(channel.getHistory(params));
      if (err || !history) {
        log.error('Failed to get history', err);
        break;
      }

      const { messages } = history;
      discoveredMessages.unshift(...messages);

      if (messages.length < 20 || discoveredMessages.length >= 50) {
        shouldGetMore = false;
      }
    }

    return discoveredMessages;
  }

  deduplicateAndStoreRawMessages(messages: MyTimeInChatMessage[]): Map<string, MyTimeInChatMessage> {
    const deDuped = new Map<string, MyTimeInChatMessage>();

    for (const message of messages) {
      if (message.deleted) continue; // Skip deleted messages

      const key = `${message.timetoken}-${message.userId}`;
      deDuped.set(key, message); // Message is already formatted, no need to wrap

      // Store raw message in chatStore
      // @ts-ignore
      this.chatStore.rawMessages.set(message.timetoken, markRaw(message));
    }

    return deDuped;
  }

  collectMissingUserIds(deDupedMessages: Map<string, MyTimeInChatMessage>, allMessages: MyTimeInChatMessage[]): string[] {
    const knownUserIds = new Set<string>(this.chatStore.knownUsersByUuid.keys());
    const missingUserIds = new Set<string>();

    // Check deduplicated messages for missing users
    for (const msg of deDupedMessages.values()) {
      if (!knownUserIds.has(msg.userId)) {
        missingUserIds.add(msg.userId);
      }
    }

    // Check reactions for missing users
    for (const message of allMessages) {
      this.chatStore.knownReactionsToMessages.set(`${message.timetoken}`, message.reactions);

      for (const reactionUsers of Object.values(message.reactions)) {
        for (const { uuid } of reactionUsers) {
          if (!knownUserIds.has(uuid)) {
            missingUserIds.add(uuid);
          }
        }
      }
    }

    return Array.from(missingUserIds);
  }

  loadChannelMessages = async (id: string) => {
    const channel = this.chatStore.knownChannels.get(id) as Channel;
    if (!channel) throw new Error('Channel not found');

    if (this.isChannelLoadingOrFinished(id)) {
      this.logSkipLoading(id);
      return;
    }

    this.loadingChannels.add(id);
    const previousLength = this.chatStore.channelMessages.get(id)?.length ?? 0;

    const discoveredMessages = await this.fetchAllMessages(channel, id);
    // Convert newly discovered messages into the same format as stored messages
    const formattedDiscoveredMessages = discoveredMessages.map(wrapPubnubMessageToFriendlyObservable);

    // Retrieve existing messages safely
    const existingMessages = this.chatStore.channelMessages.get(id) ?? [];

    // Ensure all messages are of the same type before merging
    const combinedMessages: MyTimeInChatMessage[] = [...formattedDiscoveredMessages, ...existingMessages];

    const deDupedMessages = this.deduplicateAndStoreRawMessages(combinedMessages);
    this.chatStore.channelMessages.set(id, Array.from(deDupedMessages.values()));

    if (this.chatStore.channelMessages.get(id)?.length === previousLength) {
      this.finishedLoadingChannels.add(id);
    }

    const missingUserIds = this.collectMissingUserIds(deDupedMessages, combinedMessages);
    this.loadingChannels.delete(id);
    this.chatStore.currentChannelMutations++;

    this.updateKnownUsers(missingUserIds).catch(() => {});
  };

  pruneEditMessageActions = (parentTimetoken: string, timetokens: string[]) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }
    const pendingRequests = [] as Promise<any>[];

    for (const timeToken of timetokens) {
      const originalMessage = this.chatStore.rawMessages.get(parentTimetoken);
      if (!originalMessage) {
        log.error('Could not find original message');
        continue;
      }

      pendingRequests.push(
        this.chat.sdk.removeMessageAction({
          channel: originalMessage.channelId,
          messageTimetoken: originalMessage.timetoken,
          actionTimetoken: timeToken,
        })
      );
    }

    return Promise.all(pendingRequests);
  };

  getSubscribedChannels = () => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }
    return this.chat.sdk.getSubscribedChannels();
  };

  updateChannel = async (id: string, newParticipants: ChatUser[]) => {
    const channel = this.chatStore.knownChannels.get(id);

    if (!channel) {
      throw new Error('Channel not found');
    }

    if (newParticipants.length === 0) {
      return;
    }

    if (channel.id.startsWith('direct.')) {
      // if the original channel was a direct channel and we are adding users to it, we need to actually make a new group channel instead
      const currentParticipants = (await channel.getMembers()).members.map((member) => member.user);
      return this.createChannel([...newParticipants, ...currentParticipants]);
    }

    const appStore = useAppStore();
    const payload = {
      channel_id: channel.id,
      invite_uuids: newParticipants.map((user) => user.id),
    };

    const [inviteMembersError] = await withCatch(appStore.api.inviteMembersToChatChannel(payload));

    if (inviteMembersError) {
      log.error('failed to invite multiple users');
      log.error(inviteMembersError);
      return;
    }
  };

  createChannel = (participants: ChatUser[]) => {
    if (participants.length === 1) {
      log.info('Creating direct conversation');
      return this.createDirectConversation(participants[0]);
    }
    log.info('Creating group conversation');
    return this.createGroupConversation(participants);
  };

  createGroupConversation = async (participants: ChatUser[]) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }

    if (!this.chat.currentUser?.name) {
      log.error('User not known');
      return;
    }

    const appStore = useAppStore();
    const payload = {
      invite_uuids: participants.map((user) => user.id),
      type: 'group',
    };

    const [createChatChannelError, data] = await withCatch(appStore.api.createChatChannel(payload));
    if (createChatChannelError || !data) {
      log.error('Failed to create group channel');
      log.error(createChatChannelError);
      log.error(createChatChannelError.status);
      return;
    }

    const { channel_id } = data;
    await this.refreshTokenAsync();
    await this.joinNewlyCreatedChannel(channel_id);

    return channel_id;
  };

  joinNewlyCreatedChannel = async (channelId: string) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }

    const currentlySubscribedChannels = this.chat?.sdk.getSubscribedChannels() ?? [];
    if (currentlySubscribedChannels.includes(channelId)) {
      this.changeChannel(channelId).then();
      return;
    }

    const [error, channel] = await withCatch(this.chat.getChannel(channelId));
    if (error || !channel) {
      log.error('failed to get channel information');
      log.error(error);
      return;
    }

    const joinResult = await this.connectToChannel(channel);
    if (!joinResult) {
      log.error('failed to join channel');
      return;
    }

    this.changeChannel(channel.id).then();
  };

  refreshTokenAsync = async () => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }

    const appStore = useAppStore();
    log.info('Refreshing chat token async');
    const [refreshTokenError, authentication] = await withCatch(appStore.api.refreshChatToken());

    if (refreshTokenError || !authentication) {
      log.error('Failed to refresh chat token.');
      log.error(refreshTokenError);
      throw refreshTokenError;
    }

    this.authentication = authentication;
    const [getSignedCookieError, _signedCookieData] = await withCatch(appStore.api.getSignedCookie(this.authentication.token));

    if (getSignedCookieError) {
      log.error('Failed to get signed cookie.');
      log.error(getSignedCookieError);
      throw getSignedCookieError;
    }

    this.tokenExpirationTime = new Date(Date.now() + this.authentication.ttl * 1000 - 10000);

    log.info('Applying new token to chat SDK');
    this.chat.sdk.setToken(this.authentication.token);
  };

  forceLoadCurrentParticipants = async () => {
    const now = DateTime.now();
    const THRESHOLD_MINUTES = 5;
    if (this.currentChannelLastCheckedAt && now.diff(this.currentChannelLastCheckedAt).as('minutes') < THRESHOLD_MINUTES) {
      log.info('Skipping force load of current participants less than 5 minutes since last check');
      return;
    }
    log.info('Force loading current participants');

    await this.loadChannelParticipants(this.chatStore.currentChannelId);
    this.currentChannelLastCheckedAt = now;
  };

  isCurrentlyConnectedTo = (id: string): boolean => {
    if (!this.chat) {
      return false;
    }
    return this.chat.sdk.getSubscribedChannels().includes(id);
  };

  refreshChatToken = async (fromErrorState = false) => {
    log.info('Refreshing chat token');

    if (this.busyUpdatingToken) {
      log.info('Token refresh already in progress');
      return;
    }

    const appStore = useAppStore();
    if (!appStore.currentUser) {
      log.error('User not known. Could not refresh token.');
      return;
    }

    this.busyUpdatingToken = true;

    if (fromErrorState && this.currentRecoveryAttempts > 5) {
      log.error('Too many recovery attempts, giving up.');
      return;
    }

    if (fromErrorState) {
      //TODO I don't think our recovery attempts is properly resetting is this code ever getting hit more than once?
      log.info('Recovering from error state');
      this.currentRecoveryAttempts++;
      this.disconnect();

      log.info(`Re-initializing entire chat app after token refresh due to lost connection. User ID: mytimein-user-${appStore.currentUser.id}`);

      const [reinitError] = await withCatch(this.initializeChat());
      this.busyUpdatingToken = false;
      this.currentRecoveryAttempts = 0;

      if (reinitError) {
        log.error('Failed to reinitialize chat app');
        log.error(reinitError);
        return;
      }

      return;
    }

    log.info('Fetching another token');

    const [refreshError, auth] = await withCatch(appStore.api.refreshChatToken());
    this.busyUpdatingToken = false;

    if (refreshError || !auth) {
      log.error('Failed to refresh chat token');
      log.error(refreshError);
      return;
    }

    log.debug(`Received authentication token response: `, auth);
    this.authentication = auth;
    this.tokenExpirationTime = new Date(Date.now() + auth.ttl * 1000 - 10000);
    if (this.chat && this.chat.sdk) {
      this.chat.sdk.setToken(auth.token);
    }

    // set our cookie
    const [getCookieError] = await withCatch(appStore.api.getSignedCookie(auth.token));
    if (getCookieError) {
      log.error('Failed to get signed cookie');
      log.error(getCookieError);
    }
  };

  createDirectConversation = async (user: ChatUser) => {
    if (!this.chat || !this.chat.currentUser?.name) {
      throw new Error('Chat not initialized');
    }

    const appStore = useAppStore();
    const [createDirectChannelError, data] = await withCatch(appStore.api.createDirectChatChannel(user.id));

    if (createDirectChannelError || !data) {
      log.error('Failed to create direct channel');
      log.error(createDirectChannelError);
      return;
    }

    const { channel_id } = data;

    await this.refreshTokenAsync();
    log.info('Joining newly created channel', channel_id);
    await this.joinNewlyCreatedChannel(channel_id);

    return channel_id;
  };

  updateKnownUsers = async (missingIds: string[]) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }
    for (const id of missingIds) {
      if (id === 'unknown-user') {
        // too early?
        return;
      }
      if (id === 'PUBNUB_INTERNAL_MODERATOR') {
        this.chatStore.knownUsersByUuid.set(id, {
          id: id,
          name: 'Moderator',
          profileUrl: '',
          statusMessage: '',
        });
        return;
      }
      const [getUserError, user] = await withCatch(this.chat.getUser(id));

      if (getUserError || !user) {
        log.error('Failed to query user');
        log.error(getUserError);
        log.error(getUserError.status);
        return;
      }
      if (user.active && !this.chatStore.onlineUserIds.has(user.id)) {
        this.chatStore.onlineUserIds.add(user.id);
      }
      const unobservedUser = {
        id: user.id,
        name: user.name ?? 'Unknown',
        profileUrl: user.profileUrl ?? '',
        statusMessage: `${user.custom?.statusMessage ?? ''}`,
      };
      this.chatStore.knownUsersByUuid.set(user.id, unobservedUser);
      ChatCacheService.getInstance().addUser(unobservedUser).catch();
    }
  };

  watchPresence = async (id: string) => {
    if (this.currentChannelParticipantListStreamDisconnectFunction) {
      log.info('Unregistering the current presence watch.');
      this.currentChannelParticipantListStreamDisconnectFunction();
    }

    if (!this.chat) {
      throw new Error('Chat not initialized');
    }

    const channel = this.chatStore.knownChannels.get(id);

    if (!channel) {
      throw new Error('Channel not found');
    }

    const streamPresenceCallback = (userIds: string[]) => {
      if (userIds.length === 0) {
        log.warn('got empty response from streamPresence?');
        return;
      }
    };

    const [presenceError, disconnect] = await withCatch(channel.streamPresence(() => streamPresenceCallback));
    if (presenceError || !disconnect) {
      log.error('Failed to stream presence');
      log.error(presenceError);
      return;
    }

    this.currentChannelParticipantListStreamDisconnectFunction = disconnect;
  };

  applyReactionToMessage = async (timetoken: string, emoji: string) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }

    const channel = this.chatStore.knownChannels.get(this.chatStore.channelId);
    if (!channel) {
      throw new Error('Channel not found');
    }

    let discoveredMessage = this.chatStore.rawMessages.get(timetoken);
    if (!discoveredMessage) {
      log.info(discoveredMessage);
      log.error('Could not find message in rawMessages');
      return;
    }

    if (!discoveredMessage.toggleReaction) {
      await this.refreshMessageFromPubnub(discoveredMessage.channelId, timetoken);
      discoveredMessage = this.chatStore.rawMessages.get(timetoken);
    }

    if (!discoveredMessage || !discoveredMessage.toggleReaction) {
      log.error("Okay we really couldn't find the message");
      return;
    }

    const [toggleReactionError] = await withCatch(discoveredMessage.toggleReaction(emoji));
    if (toggleReactionError) {
      //IDK Why this so lets check
      if (typeof toggleReactionError.includes === 'function' && toggleReactionError.includes('status of 409 (Conflict)')) {
        await this.refreshMessageFromPubnub(discoveredMessage.channelId, timetoken);
        return;
      }
      log.error('Failed to toggle reaction');
      log.error(toggleReactionError);
      log.error(toggleReactionError.status);
      throw toggleReactionError;
    }
  };

  refreshMessageFromPubnub = async (channelId: string, timetoken: string) => {
    const channel = this.chatStore.knownChannels.get(channelId);
    if (!channel) {
      log.error('Channel not found');
      return;
    }
    const [getMessageError, updatedMessage] = await withCatch(channel.getMessage(`${timetoken}`));
    if (getMessageError || !updatedMessage) {
      log.error('Failed to get message');
      log.error(getMessageError);
      return;
    }

    this.updateMessage(updatedMessage);
  };

  updateMessage = (message: Message) => {
    const messages = this.chatStore.channelMessages.get(message.channelId);
    if (!messages) {
      return;
    }
    const index = messages.findIndex((m) => m.timetoken === message.timetoken);
    if (index > -1) {
      messages.splice(index, 1, wrapPubnubMessageToFriendlyObservable(message));
    } else {
      messages.push(wrapPubnubMessageToFriendlyObservable(message));
    }

    this.chatStore.rawMessages.set(message.timetoken, markRaw(message));
  };

  dumpCurrentlyTypingList = () => {
    for (const timeout of this.chatStore.currentlyTyping.values()) {
      clearTimeout(timeout);
    }
    this.chatStore.currentlyTyping.clear();
  };

  /**
   * Keeps track of typers inside of this.currentlyTyping, as well as dropping them
   * from the store when required
   *
   * @param typing
   */
  typingCallbackHandler = (typing: string[]) => {
    if (typing.length === 0) {
      this.chatStore.currentlyTyping.clear();
      return;
    }
    for (const userId of typing) {
      if (userId === 'unknown-user' || userId === this.chatStore.chatUserId) {
        continue;
      }
      if (this.currentlyTyping.has(userId)) {
        clearTimeout(this.currentlyTyping.get(userId));
      }
      if (!this.chatStore.currentlyTyping.has(userId)) {
        this.chatStore.currentlyTyping.add(userId);
      }
      this.currentlyTyping.set(
        userId,
        setTimeout(() => {
          this.currentlyTyping.delete(userId);
        }, 5000)
      );
    }
  };

  startCurrentChannelInterval = (channel: Channel) => {
    this.stopCurrentChannelInterval();
    // default threshold of 20 minutes
    let updateEvery = 1000 * 60 * 20;
    const currentUserId = this.chat?.currentUser?.id ?? `-1`;
    // If they are an operator of the channel or the channel is a direct or group channel, update every 5 min
    if (this.chatStore.operators.get(channel.id)?.includes(currentUserId) || !channel.id.includes('team_')) {
      updateEvery = 1000 * 60 * 5;
    }
    log.info('Starting current channel interval with update every ' + updateEvery + 's');
    this.currentChannelUpdaterInterval = setInterval(async () => {
      await this.loadChannelParticipants(channel.id);
      this.currentChannelLastCheckedAt = DateTime.now();
    }, updateEvery);
  };

  stopCurrentChannelInterval = () => {
    if (this.currentChannelUpdaterInterval) {
      log.info('Clearing current channel interval');
      clearInterval(this.currentChannelUpdaterInterval);
      this.currentChannelUpdaterInterval = null;
    }
  };

  restoreMutedChannels = () => {
    const mutedChannels = localStorage.getItem('chat-muted-channels');
    if (!mutedChannels) {
      return;
    }
    const parsed = JSON.parse(mutedChannels);
    this.chatStore.mutedChannels = new Set(parsed);
  };

  restoreNotifications = () => {
    const alerts = localStorage.getItem('chat-alerts');
    const mentions = localStorage.getItem('chat-mentions');
    this.chatStore.channelAlerts = alerts ? restoreSerializedMap(alerts) : new Map<string, number>();
    this.chatStore.unreadMentions = mentions ? restoreSerializedMap(mentions) : new Map<string, number>();
  };

  streamUpdatesOnCurrentChannelList = () => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }
    if (this.currentChannelListDisconnectFunction) {
      log.debug('removing channel list watch and registering another channel list watch');
      this.currentChannelListDisconnectFunction();
    }

    const channels = Array.from(this.chatStore.knownChannels.values());
    if (channels.length < 1) {
      log.info('No channels to watch');
      return;
    }

    this.currentChannelListDisconnectFunction = Channel.streamUpdatesOn(Array.from(this.chatStore.knownChannels.values()) as Channel[], async (channels) => {
      for (const channel of channels) {
        if (!this.chat) {
          log.error('Chat not initialized, reloading.');
          window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
          return;
        }
        const currentChannel = this.chatStore.knownChannels.get(channel.id);

        if (!currentChannel) {
          continue;
        }

        const [getChannelError, updatedChannel] = await withCatch(this.chat.getChannel(channel.id));

        if (getChannelError || !updatedChannel) {
          log.error('Failed to get channel');
          log.error(getChannelError);
          continue;
        }
        log.info('Got a channel update', updatedChannel);
        // @ts-ignore
        currentChannel.name = updatedChannel.name ?? currentChannel.name;
        // @ts-ignore
        currentChannel.custom = updatedChannel.custom ?? currentChannel.custom;
        // @ts-ignore
        currentChannel.description = updatedChannel.description ?? currentChannel.description;
      }
    });
  };

  loadUserFromStorage = () => {
    const chatCacheInstance = ChatCacheService.getInstance();
    chatCacheInstance
      .getUsers()
      .then((users) => {
        if (users && users.length) {
          this.chatStore.knownUsersByUuid = new Map(users.map((user) => [user.id, user]));
        }
      })
      .catch((_usersErrors) => {});
  };

  loadChannelOrderFromStorage = () => {
    const chatCacheInstance = ChatCacheService.getInstance();
    chatCacheInstance
      .getChannelOrder()
      .then((channelOrder) => {
        if (channelOrder) {
          this.chatStore.globalChannelOrder = channelOrder;
        }
      })
      .catch((_chanelOrderError) => {});
  };

  getChannelsEnabledByACM = (): string[] => {
    if (!this.chat) {
      return [];
    }

    const channels = this.chat.sdk.parseToken(this.authentication.token)?.resources?.channels || {};
    if (Object.keys(channels).length === 0) {
      log.warn('Channels are empty! We probably should abort, but we will try to continue');
    }
    return Object.keys(channels);
  };

  joinChannel = async (channel: Channel) => {
    log.info('Joining channel', channel.id);
    this.chatStore.knownChannels.set(channel.id, markRaw(channel));
    const [joinError] = await withCatch(this.connectToChannel(channel));

    if (joinError) {
      log.error('Failed to join channel.');
      log.error(joinError);
      return;
    }

    log.info('Joined channel: ', channel.id);
  };

  leaveChannel = async (channelId: string) => {
    const channel = this.chatStore.knownChannels.get(channelId);
    if (!channel) {
      return;
    }
    const [leaveError] = await withCatch(channel.leave());
    if (leaveError) {
      log.error('Failed to leave channel');
      log.error(leaveError);
    }

    this.chatStore.knownChannels.delete(channelId);
    this.chatStore.channelMessages.delete(channelId);
  };

  muteUserFromMessage = async (message: MyTimeInChatMessage, _timeout: number) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }

    const [setRestrictionError] = await withCatch(this.chat.setRestrictions(message.userId, message.channelId, { mute: true }));
    if (setRestrictionError) {
      log.error('Failed to set mute restrictions');
      log.error(setRestrictionError);
      log.error(setRestrictionError.status);
      return;
    }
  };

  connectToChannel = async (channel: Channel) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }
    const currentlySubscribedChannels = this.chat.sdk.getSubscribedChannels();

    if (currentlySubscribedChannels.includes(channel.id)) {
      log.debug('found hot subscription, removing it to prevent double subscribe', channel.id);
      //@ts-ignore this isn't a promise pubnub types are fucking awful
      this.chat.sdk.unsubscribe({ channels: [channel.id] });
    }

    const [error, response] = await withCatch(channel.join(this.handleNewMessageOnChannel));

    // Handle initial failure with retry logic
    if (error || !response) {
      log.error('Failed to join channel');
      log.error(error);

      //Retry handler
      for (let attempt = 1; attempt <= 5; attempt++) {
        log.info(`Attempting to connect to channel. Attempt ${attempt} of 5`);

        const [joinError, retryResponse] = await withCatch(channel.join(this.handleNewMessageOnChannel));

        if (joinError || !retryResponse) {
          log.info(`Attempt ${attempt} failed. Retrying in 3000ms...`);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          continue;
        }

        const [connectToChannelError] = await withCatch(this.connectToChannelFinished(channel, retryResponse.disconnect));

        if (connectToChannelError) {
          log.error('Failed in retry channel setup.');
          log.error(error);
        }

        return true;
      }

      log.error(`All 5 attempts failed. Giving up.`);
      return false;
    }

    this.connectToChannelFinished(channel, response.disconnect).catch((error) => {
      log.error('Failed to connect to channel');
      log.error(error);
    });

    return true;
  };

  loadChannelParticipants = async (id: string, nextPageLink?: string) => {
    const channel = this.chatStore.knownChannels.get(id);
    if (!channel) {
      return;
    }
    const payload = {
      limit: 100,
      page: {
        next: nextPageLink,
      },
    };

    const [getMembersError, members] = await withCatch(channel.getMembers(payload));

    if (getMembersError || !members) {
      log.error('Failed to get channel members');
      log.error(getMembersError);
      return;
    }

    if (!this.chatStore.allChannelMembers.has(id)) {
      this.chatStore.allChannelMembers.set(id, new Set());
    }
    const userDetailsToCache: KnownChatUser[] = [];
    const allMemberNames = [];

    for (const member of members.members) {
      this.chatStore.allChannelMembers.get(id)?.add(member.user.id);
      if (member.user.active) {
        this.chatStore.onlineUserIds.add(member.user.id);
      }
      if (member.user.custom?.trackerStatus === 'IDLE') {
        this.chatStore.idleUserIds.add(member.user.id);
      }
      const knownUser = {
        id: member.user.id,
        name: member.user.name ?? 'Unknown',
        profileUrl: member.user.profileUrl ?? '',
        status: `${member.user.custom?.trackerStatus ?? 'UNKNOWN'}`,
        statusMessage: `${member.user.custom?.statusMessage ?? ''}`,
      };
      this.chatStore.knownUsersByUuid.set(member.user.id, knownUser);
      userDetailsToCache.push(knownUser);
      if (member.user.id !== this.chat?.currentUser?.id) {
        allMemberNames.push(member.user.name);
      }
    }

    ChatCacheService.getInstance().addUsers(userDetailsToCache).then().catch();
    this.syncChannelToCache(id);
    if (members.page.next && members.page.next !== nextPageLink) {
      await this.loadChannelParticipants(id, members.page.next);
    }

    if (!id.includes('team_')) {
      this.chatStore.channelNames.set(id, allMemberNames.sort().join(', '));
    } else {
      this.chatStore.channelNames.set(id, channel?.name ?? id);
    }
  };

  syncChannelToCache(id: string) {
    // TODO: we cant pass reactive objects here
    const channel = this.chatStore.knownChannels.get(id);
    if (!channel) {
      return;
    }
    const channelMembers = this.chatStore.allChannelMembers.get(id);
    const members = channelMembers ? Array.from(channelMembers) : [];
    const knownChannel: KnownChatChannel = {
      id: `${channel.id}`,
      name: `${channel.name}`,
      description: `${channel.description}`,
      custom: {
        cover: channel.custom?.cover as string,
        type: channel.custom?.type as string,
        team_id: channel.custom?.team_id as string,
        operators: channel.custom?.operators as string,
      },
      members: members,
    };
    ChatCacheService.getInstance().addChannel(knownChannel).then().catch();
  }

  parseChannelOperators = (channel: Channel) => {
    if (!channel.custom?.operators) {
      return;
    }
    try {
      const operators = JSON.parse(channel.custom.operators as string);
      if (Array.isArray(operators)) {
        this.chatStore.operators.set(channel.id, operators);
      }
    } catch (e) {
      log.error('failed to parse operators json');
    }
  };

  connectToChannelFinished = async (channel: Channel, disconnect: () => void) => {
    this.chatStore.knownChannels.set(channel.id, channel);
    this.channelDisconnectFns.set(channel.id, disconnect);
    await this.loadChannelParticipants(channel.id);
    this.parseChannelOperators(channel);
    await this.extractPinnedMessagesForChannel(channel);
  };

  extractPinnedMessagesForChannel = async (channel: Channel) => {
    let channelPins = channel.custom?.pins ? JSON.parse(channel.custom.pins as string) : [];
    if (!Array.isArray(channelPins)) {
      channelPins = [];
    }

    for (const pin of channelPins) {
      const [getMessageError, message] = await withCatch(channel.getMessage(pin));
      if (getMessageError) {
        log.error('Failed to get pinned message');
        log.error(getMessageError);
        continue;
      }
      if (!message) {
        //message is not pinned
        continue;
      }

      addToArrayInMap(this.chatStore.pinnedMessagesForChannels, channel.id, wrapPubnubMessageToFriendlyObservable(message));
    }
  };

  handleNewMessageOnChannel = (message: Message) => {
    this.chatStore.globalChannelOrder = [message.channelId, ...this.chatStore.globalChannelOrder.filter((c) => c !== message.channelId)];
    ChatCacheService.getInstance()
      .setChannelOrder([...this.chatStore.globalChannelOrder])
      .then()
      .catch();

    // this needs to happen to set the user who sent the message online early
    this.chatStore.onlineUserIds.add(message.userId);
    this.chatStore.rawMessages.set(message.timetoken, markRaw(message));
    this.chatStore.channelMessages.get(message.channelId)?.push(wrapPubnubMessageToFriendlyObservable(message));

    // if we are not on the current channel, we need to stream message updates on this message by itself for now
    if (this.chatStore.currentChannelId !== message.channelId) {
      // check to see if this channel id has any messages currently streaming on it
      if (!this.pendingMessageUpdateStreams.has(message.channelId)) {
        this.pendingMessageUpdateStreams.set(message.channelId, []);
      }
      const individualMessageUpdateStream = message.streamUpdates((message) => {
        this.updateMessage(message);
      });
      // append the message to the pending message update stream
      this.pendingMessageUpdateStreams.get(message.channelId)?.push(individualMessageUpdateStream);
    }

    /**
     * Stop the sender from typing immediately if they were previously typing a message
     */
    if (this.chatStore.currentChannelId === message.channelId) {
      if (this.chatStore.currentlyTyping.has(message.userId)) {
        clearTimeout(this.currentlyTyping.get(message.userId));
        this.chatStore.currentlyTyping.delete(message.userId);
      }
    }

    /**
     * Check if we don't know this user at all
     */
    const user = this.chatStore.knownUsersByUuid.get(message.userId);
    if (!user) {
      this.updateKnownUsers([message.userId]).then().catch();
    } else {
      user.status = 'ACTIVE';
      this.chatStore.knownUsersByUuid.set(message.userId, user);
    }

    const isUserMentioned = Object.values(message.mentionedUsers as MessageMentionedUsers).some((user) => user.id === this.chat?.currentUser?.id);
    const mentionEveryone = message?.meta?.mentionEveryone;
    const mentionHere = message?.meta?.mentionHere;
    const isDifferentChannel = this.chatStore.currentChannelId !== message.channelId;
    const isChannelMuted = this.chatStore.mutedChannels.has(message.channelId);
    const isNotFocused = !this.isWindowFocused;
    const isNotInChatRoute = this.currentRouteName !== 'chat';
    const shouldAlertDueToDifferentChannelFocus = isDifferentChannel && this.isWindowFocused;
    const shouldNotify = isNotFocused || isNotInChatRoute || shouldAlertDueToDifferentChannelFocus;
    const hasMention = isUserMentioned || mentionEveryone || mentionHere;

    if (shouldNotify) {
      if (hasMention) {
        this.chatStore.addMention(message.channelId);
        const params = this.createNotificationParamsFromHtml(message);
        sendNotification(params);
      }

      if (!isChannelMuted) {
        AudioService.getInstance().playBoopSound().catch();
        this.chatStore.incrementAlerts(message.channelId);
        this.bounceDock();
      }
    } else {
      this.chatStore.currentChannelMutations++;
    }
  };

  bounceDock = () => {
    invoke('bounce_dock_icon').catch((error) => {
      log.error('failed to bounce dock icon');
      log.error(error);
    });
  };

  deleteMessageFromChannel = (channelId: string, timetoken: string) => {
    const messageIndexToRemove = this.chatStore.channelMessages.get(channelId)?.findIndex((m) => m.timetoken === timetoken);
    if (messageIndexToRemove === -1 || messageIndexToRemove === undefined) {
      return;
    }
    this.chatStore.channelMessages.get(channelId)?.splice(messageIndexToRemove, 1);
    // remove it from the rawMessages as well
    this.chatStore.rawMessages.delete(timetoken);
  };

  deleteMessage = async (timetoken: string) => {
    const targetMessage = this.chatStore.rawMessages.get(timetoken);
    if (!targetMessage) return;

    const { channelId } = targetMessage;
    const channelMessages = this.chatStore.channelMessages.get(channelId);
    const targetIndex = channelMessages?.findIndex((m) => m.timetoken === timetoken);

    if (targetIndex === undefined || targetIndex < 0) return;

    const deleteMessageSafely = async (message: typeof targetMessage): Promise<boolean> => {
      const [deleteError] = await withCatch(message.delete({ soft: true }));
      if (deleteError) {
        log.error('Failed to delete message.', deleteError);
        return false;
      }
      return true;
    };

    let deleted = false;

    if (!targetMessage.delete) {
      await this.refreshMessageFromPubnub(channelId, timetoken);
      const freshMessage = this.chatStore.rawMessages.get(timetoken);
      if (freshMessage?.delete) {
        deleted = await deleteMessageSafely(freshMessage);
      }
    } else {
      deleted = await deleteMessageSafely(targetMessage);
    }

    if (deleted) {
      this.deleteMessageFromChannel(channelId, timetoken);
    }
  };

  removePin = async (timetoken: string) => {
    const message = this.chatStore.rawMessages.get(timetoken);
    if (!message) {
      return;
    }
    const [unpinError] = await withCatch(this.appStore.api.unpinMessageInChannel(message.channelId, timetoken));

    if (unpinError) {
      log.error('Failed to unpin message');
      log.error(unpinError);
      return;
    }

    removeFromArrayInMapWithPredicate(this.chatStore.pinnedMessagesForChannels, message.channelId, (m) => m.timetoken === timetoken);
  };

  pinMessage = async (timetoken: string) => {
    const message = this.chatStore.rawMessages.get(timetoken);
    if (!message) {
      return;
    }
    const appStore = useAppStore();
    const [pinError] = await withCatch(appStore.api.pinMessageInChannel(message.channelId, timetoken));

    if (pinError) {
      log.error('Failed to pin message');
      log.error(pinError);
      return;
    }
    // @ts-ignore
    addToArrayInMap(this.chatStore.pinnedMessagesForChannels, message.channelId, wrapPubnubMessageToFriendlyObservable(message));
  };

  initializeStatusListener = () => {
    // @ts-ignore
    this.customStatusListenerFn = this.chat.addListener({ status: this.pubnubCustomStatusCallback });
  };

  initializeReactionHandler = () => {
    //@ts-ignore this is an internal function and not in the types ignore this warning this is correct
    this.customEventListenerFn = this.chat.sdk.addListener({
      messageAction: this.pubnubReactionCallback,
    });
  };

  handleAccessDenied = (statusEvent: any) => {
    if (statusEvent?.errorData && statusEvent?.category === 'PNAccessDeniedCategory') {
      //I’m so frustrated with PubNub. Why can’t the error messages just be consistent?
      if (statusEvent?.errorData?.payload?.channels) {
        const channels = statusEvent.errorData.payload.channels;
        log.error('Broken channels discovered, we cannot subscribe to these', channels);
      } else {
        log.error('Access denied no channels provided');
        log.error(statusEvent?.errorData);
      }
      return;
    }
    if (statusEvent?.errorData?.message === 'Token is expired.') {
      log.error('Access denied, token expired');
      this.refreshChatToken(true).catch((refreshError) => {
        log.error('Unknown error occurred while refreshing chat token.');
        log.error(refreshError);
      });
      return;
    }

    //Unknown error
    log.error(statusEvent);
  };

  pubnubCustomStatusCallback = (statusEvent: any) => {
    if (this.isErrorStatus(statusEvent)) {
      log.error('Status error', statusEvent);
    } else {
      log.info('Status', statusEvent);
    }

    if (statusEvent.operation === 'PNUnsubscribeOperation') {
      log.info('Unsubscribed from known channel', statusEvent.affectedChannels);
    } else if (statusEvent.category === 'PNAccessDeniedCategory' || statusEvent.statusCode === 403) {
      this.handleAccessDenied(statusEvent);
    } else if (statusEvent.operation === 'PNTimeoutCategory') {
      // store.reinitializeChat();
      // TODO: this should auto restore with linearRetryPolicy that is set above
    }
  };

  listenForInvites = () => {
    if (!this.chat) {
      log.error('Chat not initialized');
      return;
    }
    this.inviteListenerFn = this.chat.listenForEvents({
      channel: this.chat.currentUser?.id,
      type: 'invite',
      callback: this.inviteToChannelCallback,
    });
  };

  inviteToChannelCallback = async (event: any) => {
    log.info(event);
    //Don't listen to events from yourself
    if (event.payload.meta.createdBy === this.chat?.currentUser?.id) {
      log.debug('Ignoring event from self');
      return;
    }

    if (!this.chat) {
      log.error('Chat not initialized, reloading.');
      window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
      return;
    }

    if (this.chatStore.knownChannels.has(event.payload.channelId)) {
      log.info('Channel already known, ignoring invite');
      return;
    }

    const [memberShipError, data] = await withCatch(this.getAllMemberships());

    if (memberShipError || !data) {
      log.error('Failed to get memberships on INVITE');
      log.error(memberShipError);
      log.error(memberShipError.status);
      return;
    }

    log.debug('Got memberships', data);

    const memberships: Membership[] = data.memberships;
    // get the new membership and join the channel
    const newMembership = memberships.find((m) => m.channel.id === event.payload.channelId);

    //Nothing to join just return early
    if (!newMembership) {
      return;
    }

    // Get a new token with updated permissions
    const [refreshTokenError] = await withCatch(this.refreshTokenAsync());

    if (refreshTokenError) {
      //TODO not sure what to do if there is an error here
      throw refreshTokenError;
    }

    if (!this.authentication || !this.authentication.token) {
      log.error('No token found');
      return;
    }

    // Make sure we can join the channel for real
    const authenticationReadyFor = this.chat.sdk.parseToken(this.authentication.token);
    const channelsAvailableToJoin: string[] = authenticationReadyFor?.resources?.channels ? Object.keys(authenticationReadyFor.resources.channels) : [];

    if (channelsAvailableToJoin.length === 0) {
      log.warn('Channels are empty! We probably should abort, but we will try to continue');
    }

    if (!channelsAvailableToJoin.includes(event.payload.channelId)) {
      log.error('bad membership data discovered', event.payload.channelId);
      return;
    }
    // join the channel now
    this.chatStore.knownChannels.set(newMembership.channel.id, newMembership.channel);
    const [connectToChannelError] = await withCatch(this.connectToChannel(newMembership.channel));

    // add this new channel to the front of the channel order
    this.chatStore.globalChannelOrder = [newMembership.channel.id, ...this.chatStore.globalChannelOrder.filter((c) => c !== newMembership.channel.id)];

    if (connectToChannelError) {
      //TODO what should we do if they fail to connect? Retry?
      log.error('Failed to connect to channel');
      log.error(connectToChannelError);
    }
  };

  getAllMemberships = async (): Promise<{ memberships: Membership[] }> => {
    if (!this.chat) {
      return {
        memberships: [],
      };
    }
    const memberships = await this.loadMembershipPage(null, []);
    return {
      memberships,
    };
  };

  loadMembershipPage = async (nextPage: string | null, memberships: Membership[]): Promise<Membership[]> => {
    if (!this.chat) {
      return [];
    }

    let memberArgs: Record<string, any> = {};

    if (nextPage !== null) {
      memberArgs = {
        page: {
          next: nextPage,
        },
      };
    }

    // Fetch memberships with error handling
    const [error, data] = await withCatch(this.chat.currentUser.getMemberships(memberArgs));

    if (error) {
      return [];
    }

    // Process results
    const allMemberships = (data?.memberships ?? []).concat(memberships);

    // Handle pagination
    if (data?.page?.next && nextPage !== data.page.next) {
      return this.loadMembershipPage(data.page.next, allMemberships);
    }

    return allMemberships;
  };

  setMyStatus = async (status: string) => {
    if (!this.chat) {
      throw new Error('Chat not initialized');
    }
    const payload = {
      custom: {
        salesforce_id: `${this.chat.currentUser.custom?.salesforce_id ?? ''}`,
        role: 'member',
        group: `${this.chat.currentUser.custom?.group ?? ''}`,
        statusMessage: status.substring(0, 100),
      },
    };

    const [updateStatusError] = await withCatch(this.chat.currentUser.update(payload));
    if (updateStatusError) {
      log.error('Failed to update status');
      log.error(updateStatusError);
      return;
    }

    // Update the logged in user for immediate update(s)
    const user = this.chatStore.knownUsersByUuid.get(this.chat.currentUser.id);
    if (user) {
      user.statusMessage = status;
      this.chatStore.knownUsersByUuid.set(this.chat.currentUser.id, user);
    }

    this.broadcastUpdateNotice();
  };

  handleMemberships = async () => {
    log.info('Handling Memberships');

    if (!this.chat) {
      throw 'Chat not initialized';
    }

    const [membershipsError, data] = await withCatch(this.getAllMemberships());
    const [unreadMessagesError, unreadMessagesInChannels] = await withCatch(this.chat.getUnreadMessagesCounts());

    if (unreadMessagesInChannels && !unreadMessagesError) {
      unreadMessagesInChannels.forEach((unreadInformation) => {
        if (this.chatStore.channelAlerts.get(unreadInformation.channel.id) && this.chatStore.channelAlerts.get(unreadInformation.channel.id)! > unreadInformation.count) {
          return;
        }
        this.chatStore.channelAlerts.set(unreadInformation.channel.id, unreadInformation.count);
      });
    }

    if (membershipsError || !data) {
      log.warn('Failed to get memberships.', membershipsError);
      return false;
    }

    const channelsEnabled = this.getChannelsEnabledByACM();
    const memberships: Membership[] = data.memberships.filter((membershipAvailable) => {
      return channelsEnabled.includes(membershipAvailable.channel.id);
    });

    // populate last raed message timestamps
    memberships
      .filter((membership) => Boolean(membership.lastReadMessageTimetoken))
      .forEach((membership) => {
        this.chatStore.lastReadTimestampPerChannel.set(membership.channel.id, parseInt(`${membership.lastReadMessageTimetoken}`));
      });

    const joins = [];

    for (const membership of memberships) {
      joins.push(this.joinChannel(membership.channel));
    }

    const [joinErrors] = await withCatch(Promise.all(joins));
    if (joinErrors) {
      log.error('Join Errors');
      log.error(joinErrors);
    }

    log.info('Memberships handled');

    return true;
  };

  pubnubReactionCallback = (event: Pubnub.Subscription.MessageAction) => {
    this.chatStore.knownChannels.forEach((channel) => {
      const messages = this.chatStore.channelMessages.get(channel.id);
      if (!messages) {
        return;
      }
      const index = messages.findIndex((m) => m.timetoken === event.data.messageTimetoken);
      const message = messages[index];
      const rawMessage = this.chatStore.rawMessages.get(event.data.messageTimetoken);
      if (!message) {
        return;
      }
      let actions: any = {};

      if (event.event === 'added' && event.data.type === 'edited') {
        // simply refresh the message and push it into the stack
        // @ts-ignore
        channel.getMessage(event.data.messageTimetoken).then((editedMessage) => {
          // @ts-ignore
          editedMessage.force_update = true;
          messages.splice(index, 1, wrapPubnubMessageToFriendlyObservable(editedMessage));
        });
        return;
      }
      if (event.event === 'added') {
        // @ts-ignore
        actions = rawMessage.assignAction(event.data);
      }
      if (event.event === 'removed') {
        console.log('got removal of an event', event.data);
        // @ts-ignore
        actions = rawMessage.filterAction(event.data);
      }
      actions = JSON.parse(JSON.stringify(actions));
      // parse over the first set of keys
      for (const type of Object.keys(actions)) {
        // console.log('examining type', type);
        for (const appliedAction of Object.keys(actions[type])) {
          // console.log('examining action key', appliedAction);
          if (actions[type][appliedAction].length === 0) {
            // console.log('should prune action key', actions[type][appliedAction]);
            delete actions[type][appliedAction];
          }
        }
      }

      // @ts-ignore
      const updatedMessage = rawMessage.clone({ actions });
      updatedMessage.force_update = true;
      if (index > -1) {
        messages.splice(index, 1, wrapPubnubMessageToFriendlyObservable(updatedMessage));
      } else {
        messages.push(wrapPubnubMessageToFriendlyObservable(updatedMessage));
      }
      this.chatStore.rawMessages.set(updatedMessage.timetoken, updatedMessage);
      this.chatStore.knownReactionsToMessages.set(updatedMessage.timetoken, updatedMessage.reactions);
    });
  };

  createNotificationParamsFromHtml = (message: Message) => {
    const htmlToRawTextWithoutMentions = (html: string): string => {
      // Create a temporary DOM element
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;

      const processNode = (node: Node) => {
        let result = '';

        node.childNodes.forEach((child: ChildNode) => {
          if (child.nodeType === Node.TEXT_NODE) {
            // If it's a text node, append its text content
            result += child.textContent;
          } else if (child.nodeType === Node.ELEMENT_NODE) {
            if ((child as HTMLElement).tagName.toLowerCase() === 'span' && (child as HTMLElement).classList.contains('mention')) {
              // Skip this node, effectively removing the mention
            } else {
              // Otherwise, process its child nodes recursively
              result += processNode(child);
            }
          }
        });

        return result;
      };

      return processNode(tempElement);
    };

    //Send system notification on mention
    return {
      title: `${this.chatStore.knownUsersByUuid.get(message.userId)?.name} sent a message`,
      body: htmlToRawTextWithoutMentions(message.text),
    };
  };

  isErrorStatus = (statusEvent: any) => {
    return ['PNUnknownCategory', 'PNBadRequestCategory', 'PNAccessDeniedCategory', 'PNTimeoutCategory', 'PNNetworkDownCategory'].includes(statusEvent.category);
  };

  broadcastOnlineNotice = () => {
    if (!this.chat) {
      throw new Error('Chat not initialized - Broadcast Online');
    }

    return true;
  };

  broadcastUpdateNotice = () => {
    if (!this.chat) {
      throw new Error('Chat not initialized - Broadcast Update');
    }

    return true;
  };

  broadcastIdleNotice = () => {
    if (!this.chat) {
      log.error('Chat not initialized - Broadcast Idle');
      return false;
    }

    return true;
  };

  disconnect = () => {
    if (!this.chat) {
      return;
    }
    if (this.currentChannelParticipantListStreamDisconnectFunction) {
      this.currentChannelParticipantListStreamDisconnectFunction();
    }
    if (this.inviteListenerFn) {
      this.inviteListenerFn();
    }
    if (this.customStatusListenerFn) {
      this.customStatusListenerFn();
    }
    if (this.customEventListenerFn) {
      this.customEventListenerFn();
    }
    if (this.currentChannelListDisconnectFunction) {
      this.currentChannelListDisconnectFunction();
    }

    this.chat.sdk.destroy(true);
    this.chatStore.$reset();
  };

  setCurrentRouteName = (name: string) => {
    this.currentRouteName = name;
  };

  setIsWindowFocused = (focused: boolean) => {
    this.isWindowFocused = focused;

    if (this.currentRouteName === 'chat' || this.isWindowFocused) {
      this.chatStore.removeAlerts(this.chatStore.channelId);
    }
  };

  /**
   * Get unread message counts from Pubnub. This is based on when messages were sent during offline periods,
   * and may conflict w/ unread notifications in our app (messages sent on channels we're not looking at)
   * We should probably take the higher of the two values when booting our app? Adding here for experimentation.
   *
   * @ref https://www.pubnub.com/docs/chat/chat-sdk/build/features/messages/unread#get-unread-messages-count-all-channels
   */
  getUnreadMessageCounts = async () => {
    if (!this.chat) {
      throw 'Chat not initialized';
    }

    // TODO: this says it should support params like { page: { next } } in the docs, but i dont see that it does give us any information that we have *more* to read?
    const [getUnReadError, unreadChannels] = await withCatch(this.chat.getUnreadMessagesCounts());

    if (getUnReadError || !unreadChannels) {
      log.error('Failed to get unread message counts.');
      log.error(getUnReadError);
      log.error(getUnReadError.status);
      return;
    }

    for (const unreadInformation of unreadChannels) {
      // if we have channel alerts and that channel alert is < the unread information.count, then skip it. Otherwise, use unreadInformation.count
      if (this.chatStore.channelAlerts.get(unreadInformation.channel.id) && this.chatStore.channelAlerts.get(unreadInformation.channel.id)! > unreadInformation.count) {
        return;
      }
      this.chatStore.channelAlerts.set(unreadInformation.channel.id, unreadInformation.count);
    }
  };
}

export default ChatService;
