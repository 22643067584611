import { init, compress, decompress } from '@bokuweb/zstd-wasm';
import wasmPathAvailable from '../../node_modules/@bokuweb/zstd-wasm/dist/web/zstd.wasm?url';
import { Logger } from '@utils/logger';
import { withCatch } from '@utils/await-safe';
const log = new Logger('zstd-service');

export class ZstdService {
  private static instance: ZstdService;

  private constructor() {}

  static async getInstance(): Promise<ZstdService> {
    if (!ZstdService.instance) {
      ZstdService.instance = new ZstdService();
      await ZstdService.initWasm();
    }
    return ZstdService.instance;
  }

  private static async initWasm(): Promise<void> {
    log.info('Loading zstd wasm', wasmPathAvailable);

    // @ts-ignore
    const [wasmInitError] = await withCatch(init(wasmPathAvailable));

    if (wasmInitError) {
      log.error(wasmInitError);
      log.error('Failed to initialize ZSTD wasm.');
    }
  }

  compress(buffer: Uint8Array, level: number = 10): Uint8Array {
    return compress(buffer, level);
  }

  //docs for this shit are stupid it is a Uint8Array not ArrayBuffer
  decompress(buffer: Uint8Array): Uint8Array {
    return decompress(buffer) as Uint8Array;
  }
}
