import { createApp } from 'vue';
import { initializeThemeControls } from '@src/bootstrap/theme-controls';
import App from './App.vue';
import { MTI_MODULES } from '@modules/.';
import { useRouter } from 'vue-router';
import heartbeatService from '@src/services/heartbeat-service';
import 'primeicons/primeicons.css';
import './style.css';
import { Logger } from '@utils/logger';

const log = new Logger('main');

/**
 * Create router and application
 */
log.info('[boot] creating app');
const app = createApp(App);
log.info('[boot] registering all modules and services');
MTI_MODULES().forEach((module) => module.onVueAppInit?.(app));
log.info('[boot] mounting');
app.mount('#app');

// TODO: should this really go here? We need to start it, but im not sure if this is the best place
// TODO: find a better point to register all services and put them into a DI container
const router = useRouter();
heartbeatService.getInstance(router);

initializeThemeControls();
