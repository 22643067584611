<script setup lang="ts">
  import { PencilSquareIcon, ArrowUturnLeftIcon, PhotoIcon, ClipboardDocumentIcon, TrashIcon, ArrowPathIcon } from '@heroicons/vue/16/solid';

  import { writeImage } from '@tauri-apps/plugin-clipboard-manager';
  import { useChatStore } from '@src/store/chat';
  import { useConfirm } from 'primevue/useconfirm';
  import { ref } from 'vue';
  import ChatService from '@src/services/chat-service';
  const chatStore = useChatStore();
  const chatService = ChatService.getInstance();
  const confirm = useConfirm();

  const emits = defineEmits(['close']);

  const canPin = ref(chatStore.currentChannelId.startsWith('direct.') || chatStore.currentChannelId.startsWith('group.'));
  const canUnpin = ref(false);
  const isChannelOperator = ref(chatStore.canOperateOnCurrentChannel);
  const isOwnMessage = ref(false);

  if (chatStore.messageContextMenuMessage) {
    canUnpin.value = chatStore.currentChannelPinnedTimetokens.includes(chatStore.messageContextMenuMessage.timetoken);
    isOwnMessage.value = chatStore.messageContextMenuMessage.userId === chatStore.chatUserId;
  }

  const close = () => {
    emits('close');
  };

  const pinMessage = () => {
    if (!chatStore.messageContextMenuMessage) {
      close();
      return;
    }
    chatService.pinMessage(chatStore.messageContextMenuMessage.timetoken);
    close();
  };

  const unpinMessage = () => {
    if (!chatStore.messageContextMenuMessage) {
      close();
      return;
    }

    chatService.removePin(chatStore.messageContextMenuMessage.timetoken);
    close();
  };

  const editMessage = () => {
    if (!chatStore.messageContextMenuMessage) {
      close();
      return;
    }

    chatStore.startEditingMessage(chatStore.messageContextMenuMessage.timetoken);
    close();
  };

  const replyMessage = () => {
    if (!chatStore.messageContextMenuMessage) {
      close();
      return;
    }

    chatStore.replyToMessage(chatStore.messageContextMenuMessage.timetoken);
    close();
  };

  const copyImageToClipboard = async () => {
    if (!chatStore.messageContextMenuMessage || !chatStore.contextMenuImage) {
      close();
      return;
    }

    const fetchOptions: RequestInit = {};

    if (import.meta.env.MODE !== 'development') {
      fetchOptions.credentials = 'include';
    }

    return fetch(chatStore.contextMenuImage.src, fetchOptions)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        writeImage(buffer);
        close();
      })
      .catch((error) => {
        console.error('Failed to fetch and write image:', error);
        close();
      });
  };

  const deleteMessage = () => {
    if (!chatStore.messageContextMenuMessage) {
      close();
      return;
    }

    confirm.require({
      message: 'Are you sure you want to delete this message?',
      header: 'Delete message?',
      blockScroll: true,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      group: 'message-delete',
      accept: () => {
        if (!chatStore.messageContextMenuMessage) {
          close();
          return;
        }

        chatService.deleteMessage(chatStore.messageContextMenuMessage.timetoken);
        close();
      },
    });
    chatStore.showMessageContextMenu = false;
  };

  const copyText = () => {
    if (!chatStore.messageContextMenuMessage) {
      close();
      return;
    }

    navigator.clipboard.writeText(chatStore.messageContextMenuMessage.text);
    close();
  };

  const reload = () => {
    chatStore.showMessageContextMenu = false;
    requestAnimationFrame(() => {
      window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
      close();
    });
  };
</script>

<template>
  <div class="select-none rounded bg-white p-2 shadow-xl min-w-44 dark:bg-surface-950">
    <ul>
      <li v-if="isOwnMessage" @click="close">
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group text-surface-700 hover:bg-primary-400 dark:text-surface-300 dark:hover:bg-primary-600" @click="editMessage">
          <div class="grow text-xs font-semibold group-hover:text-white">Edit Message</div>
          <div>
            <PencilSquareIcon class="size-[18px] text-surface-700 group-hover:text-white dark:text-surface-300" />
          </div>
        </div>
      </li>
      <li>
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group text-surface-700 hover:bg-primary-400 dark:text-surface-300 dark:hover:bg-primary-600" @click="replyMessage">
          <div class="grow text-xs font-semibold group-hover:text-white">Reply</div>
          <div class="shrink-0">
            <ArrowUturnLeftIcon class="size-[18px] text-surface-700 group-hover:text-white dark:text-surface-300" />
          </div>
        </div>
      </li>
      <li class="my-1 border-b border-surface-200 dark:border-surface-700"></li>

      <li v-if="canPin && !canUnpin">
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group text-surface-700 hover:bg-primary-400 dark:text-surface-300 dark:hover:bg-primary-600" @click="pinMessage">
          <div class="grow text-xs font-semibold group-hover:text-white">Pin Message</div>
          <div class="shrink-0">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="size-[18px] text-surface-700 group-hover:text-white dark:text-surface-300" viewBox="0 0 24 24">
              <path d="M12 17v5M9 10.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-.76a2 2 0 0 0-1.11-1.79l-1.78-.9A2 2 0 0 1 15 10.76V7a1 1 0 0 1 1-1 2 2 0 0 0 0-4H8a2 2 0 0 0 0 4 1 1 0 0 1 1 1z" />
            </svg>
          </div>
        </div>
      </li>
      <li v-if="canPin && canUnpin">
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group text-surface-700 hover:bg-primary-400 dark:text-surface-300 dark:hover:bg-primary-600" @click="unpinMessage">
          <div class="grow text-xs font-semibold group-hover:text-white">Unpin Message</div>
          <div class="shrink-0">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="size-[18px] text-surface-700 group-hover:text-white dark:text-surface-300" viewBox="0 0 24 24">
              <path d="M12 17v5M15 9.34V7a1 1 0 0 1 1-1 2 2 0 0 0 0-4H7.89M2 2l20 20M9 9v1.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V16a1 1 0 0 0 1 1h11" />
            </svg>
          </div>
        </div>
      </li>

      <li v-if="chatStore.contextMenuImage">
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group text-surface-700 hover:bg-primary-400 dark:text-surface-300 dark:hover:bg-primary-600" @click="copyImageToClipboard">
          <div class="grow text-xs font-semibold group-hover:text-white">Copy Image</div>
          <div class="shrink-0">
            <PhotoIcon class="size-[18px] text-surface-700 group-hover:text-white dark:text-surface-300" />
          </div>
        </div>
      </li>
      <li>
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group text-surface-700 hover:bg-primary-400 dark:text-surface-300 dark:hover:bg-primary-600" @click="copyText">
          <div class="grow text-xs font-semibold group-hover:text-white">Copy Message</div>
          <div class="shrink-0">
            <ClipboardDocumentIcon class="size-[18px] text-surface-700 group-hover:text-white dark:text-surface-300" />
          </div>
        </div>
      </li>
      <li v-if="isOwnMessage || isChannelOperator" class="my-1 border-b border-surface-200 dark:border-surface-700"></li>

      <li v-if="isOwnMessage || isChannelOperator">
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group hover:bg-red-500 hover:text-red-50" @click="deleteMessage">
          <div class="grow text-xs font-semibold group-hover:text-red-50">Delete Message</div>
          <div class="shrink-0">
            <TrashIcon class="text-red-500 size-[18px] group-hover:text-red-50" />
          </div>
        </div>
      </li>
      <li class="my-1 border-b border-surface-200 dark:border-surface-700"></li>
      <li>
        <div role="button" class="flex items-center gap-2 rounded p-1.5 group text-surface-700 hover:bg-primary-400 dark:text-surface-300 dark:hover:bg-primary-600" @click="reload">
          <div class="grow text-xs font-semibold group-hover:text-white">Reload</div>
          <div class="shrink-0">
            <ArrowPathIcon class="size-[18px] text-surface-700 group-hover:text-white dark:text-surface-300" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
