import { MtiModule } from '@modules/index';
import { App } from 'vue';
import { open as tauriOpen } from '@tauri-apps/plugin-shell';
import { Logger } from '@utils/logger';
const log = new Logger('Handlers');

export default <MtiModule>{
  onVueAppInit(_app: App) {
    window.tauriLinkHandler = (e: any) => {
      e.preventDefault();
      const url = e.target.href;
      if (url) {
        tauriOpen(url).catch((error) => {
          log.error(`Failed to open URL ${url}`);
          log.error(error);
        });
      }
    };

    window.tauriCopyCodeHandler = (e: any) => {
      if (!e || !e.target || !(e.target instanceof HTMLElement)) {
        return;
      }
      const targetElement = e.target as HTMLElement;
      const nearestCodeBlock = e.target.closest('pre')?.querySelector('code');
      navigator.clipboard.writeText(nearestCodeBlock?.textContent || '').then(() => {
        targetElement.innerHTML = '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd"/></svg>';
        setTimeout(() => {
          targetElement.innerHTML =
            '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z"/><path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z"/></svg>';
        }, 2000);
      });
    };
  },
};
